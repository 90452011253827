@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300&display=swap');

.App {
  text-align: center;
  font-family: 'Readex Pro';
}

body {
  background-image: url(./assets/mountains.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  font-family: 'Readex Pro';
  color:rgb(255, 255, 255);
  padding-top: 10%;
}

.icon-link {
  background-color: 'transparent';
  border-color: 'transparent';
  height: '70%';
}


.title {
  font-size: 4.5rem;
  font-weight: 700;
}

.about-section {
  text-align: left;
}

.nav-link {
  color: rgb(255, 255, 255);
  font-size: '20px';
  text-decoration: none;
}