@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300&display=swap);
body {
  margin: 0;
  font-family: Readex Pro, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Readex Pro, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Readex Pro';
}

body {
  background-image: url(/static/media/mountains.177f50e2.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  font-family: 'Readex Pro';
  color:rgb(255, 255, 255);
  padding-top: 10%;
}

.icon-link {
  background-color: 'transparent';
  border-color: 'transparent';
  height: '70%';
}


.title {
  font-size: 4.5rem;
  font-weight: 700;
}

.about-section {
  text-align: left;
}

.nav-link {
  color: rgb(255, 255, 255);
  font-size: '20px';
  text-decoration: none;
}
